import React, { useState } from 'react';
import { Card, CardMedia, CardContent, Typography, IconButton, Box } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { ADD_TO_CART, REMOVE_FROM_CART } from 'store/actions';
const CartItemCard = ({ productId, image, name, price, quantity, handleDecreaseQuantity, handleIncreaseQuantity, handleDelete }) => {
    const handleDecrease = () => {
        if (quantity > 1) {
            //setItemQuantity(itemQuantity - 1);
            handleDecreaseQuantity();
        } else {
            handleDelete();
        }
    };

    const handleIncrease = () => {
        // setItemQuantity(itemQuantity + 1);
        handleIncreaseQuantity();
    };

    return (
        <Card sx={{ padding: '2vh', paddingLeft: '0', background: 'white !important' }}>
            <Box display="flex">
                <Box display="flex" flexDirection="column" alignItems="center" marginRight={'15px'}>
                    <IconButton
                        size="small"
                        sx={{ color: 'black', border: '1px solid black', padding: '2px', marginBottom: '5px' }}
                        onClick={handleIncrease}
                    >
                        <AddIcon />
                    </IconButton>
                    <Typography variant="body1" style={{ margin: '0 8px', color: 'black' }}>
                        {quantity}
                    </Typography>
                    <IconButton
                        size="small"
                        sx={{ color: 'black', border: '1px solid black', padding: '2px', marginTop: '5px' }}
                        onClick={handleDecrease}
                    >
                        <RemoveIcon />
                    </IconButton>
                </Box>
                <Box display={'flex'} sx={{ width: '30%', marginRight: '15px' }}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent={'center'}>
                        <CardMedia component="img" image={image} title={name} />
                    </Box>
                </Box>
                <Box display="flex" flex="1" flexDirection="column" justifyContent={'center'} alignItems="flex-start">
                    <Typography variant="h5" sx={{ color: 'black' }}>
                        {name}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'black' }}>
                        €{price} x {quantity}
                    </Typography>
                    <Typography sx={{ color: 'rgb(180, 170, 130)' }} variant="h6">
                        ${price * quantity}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <IconButton size="small" onClick={handleDelete} sx={{ color: 'black' }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
        </Card>
    );
};

export default CartItemCard;
