import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import I18NextHttpBackend from 'i18next-http-backend';

const resources = {
    en: {
        translation: {
            'Welcome to React': 'Welcome to React and react-i18next',
            title: 'Hello World',
            description: {
                part1: 'first desc',
                part2: 'second desc'
            }
        }
    },
    de: {
        translation: {
            'Welcome to React': 'Bienvenue à React et react-i18next',
            title: 'Hello World from duetsch',
            description: {
                part1: 'first desc in duetsch',
                part2: 'second desc in duetsch'
            }
        }
    }
};
// /free/static
i18n.use(I18NextHttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en', // Set the fallback language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        backend: {
            loadPath: '/free/static/locales/{{lng}}/tiktag-translation.json' // Path to translation files
        },
        detection: {
            order: ['navigator'],
            cache: ['cookie']
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
