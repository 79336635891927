import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Drawer,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Button,
    Radio,
    RadioGroup,
    Slider,
    Tooltip,
    Typography,
    Box,
    Divider
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import { gridSpacing } from 'store/constant';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Close } from '@mui/icons-material';
import CartItemCard from './CartItemCard';
import { ADD_TO_CART, REMOVE_FROM_CART, DELETE_ITEM_FROM_CART } from 'store/actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// ==============================|| LIVE CUSTOMIZATION ||============================== //
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 16,
        backgroundColor: 'red'
        //border: `2px solid ${theme.palette.background.paper}`,
        //padding: '0 4px'
    }
}));
const Cart = () => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const cart = useSelector((state) => state.cart);
    const cartItems = cart.cart;
    const dispatch = useDispatch();

    const calculateTotalPrice = () => {
        let totalPrice = 0.0;
        cartItems.forEach((item) => {
            totalPrice += parseFloat(item.price);
        });
        return totalPrice;
    };
    const groupedItemsById = cartItems.reduce((group, product) => {
        const { id } = product;
        group[id] = group[id] ?? [];
        group[id].push(product);
        return group;
    }, {});
    const numOfItems = Object.entries(groupedItemsById).length;

    // drawer on/off
    const handleToggle = () => {
        setOpen(!open);
    };

    // disable render cart when there is not elements in cart
    if (cartItems.length == 0) {
        return null;
    }

    return (
        <>
            {/* toggle button */}
            <Tooltip title="View Cart">
                <Box
                    sx={{
                        '& .MuiButtonBase-root': { backgroundColor: 'black' },
                        '& .MuiButtonBase-root:hover': { backgroundColor: 'black' }
                    }}
                >
                    <Fab
                        component="div"
                        onClick={handleToggle}
                        size="medium"
                        variant="circular"
                        color="secondary"
                        sx={{
                            borderRadius: 0,
                            borderTopLeftRadius: '50%',
                            borderBottomLeftRadius: '50%',
                            borderTopRightRadius: '50%',
                            borderBottomRightRadius: '4px',
                            top: '25%',
                            position: 'fixed',
                            right: 10,
                            zIndex: theme.zIndex.speedDial
                        }}
                    >
                        <IconButton
                            aria-label="cart"
                            color="inherit"
                            size="large"
                            sx={{ position: 'absolute', right: '4px' }}
                            disableRipple
                        >
                            {cartItems.length == 0 ? (
                                <ShoppingCartIcon />
                            ) : (
                                <StyledBadge badgeContent={numOfItems}>
                                    <ShoppingCartIcon />
                                </StyledBadge>
                            )}
                        </IconButton>
                    </Fab>
                </Box>
            </Tooltip>

            <Drawer
                anchor="right"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: 380
                    }
                }}
            >
                <PerfectScrollbar component="div" style={{ background: 'white' }}>
                    <Box
                        sx={{
                            marginY: '5%',
                            marginLeft: '10%',
                            marginRight: '10%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <ShoppingCartIcon sx={{ fontSize: '4vh', color: 'black' }} />
                            <Typography variant="h3" sx={{ marginBottom: '0px', marginLeft: '5px', color: 'black' }} gutterBottom>
                                {numOfItems} {t('cart.title')}
                            </Typography>
                        </div>
                        <IconButton onClick={handleToggle} style={{ color: 'black' }}>
                            <Close />
                        </IconButton>
                    </Box>
                    <div className="d-flex justify-content-center">
                        <Divider sx={{ borderColor: 'black', width: '90%' }} />
                    </div>
                    <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                        {Object.entries(groupedItemsById).map(([itemId, arrOfItems]) => (
                            <Grid item xs={12} key={itemId}>
                                <CartItemCard
                                    key={itemId}
                                    productId={itemId}
                                    image={arrOfItems[0].image1}
                                    name={arrOfItems[0].name}
                                    price={arrOfItems[0].price}
                                    quantity={arrOfItems.length}
                                    handleDecreaseQuantity={() => {
                                        dispatch({ type: REMOVE_FROM_CART, productId: itemId });
                                    }}
                                    handleIncreaseQuantity={() => dispatch({ type: ADD_TO_CART, product: arrOfItems[0] })}
                                    handleDelete={() => dispatch({ type: DELETE_ITEM_FROM_CART, productId: itemId })}
                                />
                                <Divider />
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ position: 'sticky', bottom: 0, width: '100%', padding: '20px', backgroundColor: 'white' }}>
                        <Link to={'/checkout'}>
                            <Button
                                sx={{
                                    minWidth: '100%',
                                    background: 'transparent',
                                    border: '1px solid rgb(180, 170, 130)',
                                    color: 'rgb(180, 170, 130)',
                                    borderRadius: '35px',
                                    ':hover': {
                                        background: 'rgb(180, 170, 130)',
                                        color: 'white'
                                    }
                                }}
                                onClick={handleToggle}
                            >
                                {t('cart.button')} €{calculateTotalPrice()}
                            </Button>
                        </Link>
                    </Box>
                </PerfectScrollbar>
            </Drawer>
        </>
    );
};

export default Cart;
