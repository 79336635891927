// action - state management
import * as actionTypes from './actions';

export const initialState = {
    cart: []
};

// ==============================|| CART REDUCER ||============================== //

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_TO_CART:
            return {
                ...state,
                cart: [...state.cart, action.product]
            };
        case actionTypes.REMOVE_FROM_CART:
            const index = state.cart.findIndex((item) => item.id == action.productId);
            if (index >= 0) {
                state.cart.splice(index, 1);
            }
            return {
                ...state,
                cart: [...state.cart]
                //cart: state.cart.filter((p) => p.id != action.productId)
            };
        case actionTypes.DELETE_ITEM_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter((i) => i.id != action.productId)
                //cart: state.cart.filter((p) => p.id != action.productId)
            };
        case actionTypes.CLEAR_CART:
            return {
                ...state,
                cart: []
            };
        default:
            return state;
    }
};

export default cartReducer;
