import { lazy } from 'react';
// util.js

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import BaseLayout from 'layout/BaseLayout';
// import Signup from '../views/login/Signup';

// dashboard routing
const HomePage = Loadable(lazy(() => import('views/home/HomePage')));
const HowItWorks = Loadable(lazy(() => import('views/how-it-works/HowItWorks')));
const Teams = Loadable(lazy(() => import('views/teams/Teams')));
const OurProducts = Loadable(lazy(() => import('views/our-products/OurProducts')));
const Login = Loadable(lazy(() => import('views/login/Login')));
const Login1 = Loadable(lazy(() => import('views/login/Login1')));
const Signup = Loadable(lazy(() => import('views/login/Signup')));
const Plans = Loadable(lazy(() => import('views/plans/Plans')));
const Pricing = Loadable(lazy(() => import('views/plans/Pricing')));
const Checkout = Loadable(lazy(() => import('views/checkout/Checkout')));
const Profile = Loadable(lazy(() => import('views/profile/Profile')));
const DarkProfile = Loadable(lazy(() => import('views/profile/DarkProfile')));
const BookMeeting = Loadable(lazy(() => import('views/book-meeting/BookMeeting')));
const ForgotPassword = Loadable(lazy(() => import('views/login/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('views/login/ResetPassword')));
const TestTranslation = Loadable(lazy(() => import('views/test-translation/translation')));
const PrivacyPolicy = Loadable(lazy(() => import('views/privacy-policy/PrivacyPolicy')));
const Logout = Loadable(lazy(() => import('views/logout/Logout')));
const Error404 = Loadable(lazy(() => import('views/errors/Error404')));
// ==============================|| MAIN ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: <BaseLayout />,
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/home',
            element: <HomePage />
        },
        {
            path: '/our-products',
            element: <OurProducts />
        },
        {
            path: '/for-teams',
            element: <Teams />
        },
        {
            path: '/how-it-works',
            element: <HowItWorks />
        },
        {
            path: '/login',
            element: <Login1 />
        },
        {
            path: '/logout',
            element: <Logout />
        },
        {
            path: '/signup',
            element: <Signup />
        },
        {
            path: '/signup/:planId/:interval',
            element: <Signup />
        },
        {
            path: '/plans',
            element: <Pricing />
        },
        {
            path: '/checkout',
            element: <Checkout />
        },
        {
            path: '/profile/:cardId',
            element: <Profile />
        },
        {
            path: '/dark-profile',
            element: <DarkProfile />
        },
        {
            path: '/book-meeting',
            element: <BookMeeting />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />
        },
        {
            path: '/reset-password/:uuid_hex',
            element: <ResetPassword />
        },
        {
            path: '/test-trans',
            element: <TestTranslation />
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />
        },
        {
            path: '*',
            element: <Error404 />
        }
    ]
};

export default PublicRoutes;
