// assets
import * as React from 'react';
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import StoreIcon from '@mui/icons-material/Store';
import GroupsIcon from '@mui/icons-material/Groups';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
const StoreTitle = () => {
    return (
        <>
            Store <ArrowOutwardIcon sx={{ fontSize: 15 }} />
        </>
    );
};
const tiktag = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'my-cards',
            title: 'My Cards',
            type: 'item',
            url: '/cards',
            icon: RecentActorsOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'connections',
            title: 'Connections',
            type: 'item',
            url: '/connections',
            icon: ConnectWithoutContactOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'insight',
            title: 'Insight',
            type: 'item',
            url: '/insight',
            icon: QueryStatsOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'active-devices',
            title: 'Devices',
            type: 'item',
            url: '/active-devices',
            icon: DevicesOtherIcon,
            breadcrumbs: false
        },
        {
            id: 'groups',
            title: 'Groups',
            type: 'item',
            url: '/groups',
            icon: GroupsIcon,
            breadcrumbs: false
        },
        {
            id: 'store',
            title: 'Store',
            type: 'item',
            url: '/our-products',
            icon: StoreIcon,
            breadcrumbs: false
        }
        // {
        //     id: 'settings',
        //     title: 'Settings',
        //     type: 'item',
        //     url: '/settings',
        //     icon: SettingsIcon,
        //     breadcrumbs: false
        // }
    ]
};

export default tiktag;
