import { createRoot } from 'react-dom/client';

// third party
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import { AuthProvider } from 'providers/AuthProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FacebookProvider } from 'react-facebook';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

// init QueryClient object from react-query
const queryClient = new QueryClient();

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <QueryClientProvider client={queryClient}>
        <FacebookProvider appId="3247048992251777">
            <GoogleOAuthProvider clientId="449765539707-q5o1pe5pakqsjhm0ies0q67tab3v7og8.apps.googleusercontent.com">
                <Provider store={store}>
                    <AuthProvider>
                        <I18nextProvider i18n={i18n}>
                            <App />
                        </I18nextProvider>
                    </AuthProvider>
                </Provider>
            </GoogleOAuthProvider>
        </FacebookProvider>
    </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
