import { Outlet } from 'react-router-dom';
import Cart from '../MainLayout/Cart';
// ==============================|| MINIMAL LAYOUT ||============================== //

const BaseLayout = () => (
    <>
        <Outlet />
        <Cart />
    </>
);

export default BaseLayout;
