// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isDark: true
};

const themeReducer = (state = initialState, action) => {
    console.log('Theme Reducer : ' + JSON.stringify(action));
    switch (action.type) {
        case actionTypes.CHANGE_THEME:
            const currentTheme = action.isDark;
            console.log('Current theme : ' + currentTheme);
            return {
                ...state,
                isDark: !currentTheme
            };
        default:
            return state;
    }
};

export default themeReducer;
