import { useRoutes } from 'react-router-dom';

// routes
import PublicRoutes from './PublicRoutes';
import ProtectedRoutes from './ProtectedRoutes';

import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([PublicRoutes, ProtectedRoutes]);
}
