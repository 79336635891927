import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import profile_avatar from '../../../../views/assets/images/black-profile.png';
import dark_profile_avatar from '../../../../views/assets/images/dark-profile.png';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

import { updateThemeMode } from 'themes';
import { useDispatch } from 'react-redux';
import { CHANGE_THEME } from 'store/actions';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';
import OtherComponent from './OtherComponent';
import ThemeSection from '../ThemeSection';
import axios from 'axios';
import { useQuery } from 'react-query';
import useUserInfo from 'hooks/useUserInfo';
import { useTranslation } from 'react-i18next';
// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    // const tiktagTheme = useSelector((state) => state.theme);
    const isDarkTheme = useSelector((state) => state.theme).isDark;
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const { data, isLoading, isError } = useQuery(
        'profile-data',
        async () => {
            return (await axios.get('https://tiktag.co/api/avatar-image/')).data;
        },
        { keepPreviousData: true }
    );
    const { data: userInfo, isLoading: isLoadingUserInfo, isError: isErrorUserInfo } = useUserInfo();
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const [greetingSentence, setGreetingSentence] = useState('Hello');

    useEffect(() => {
        const date = new Date();
        const currentHour = date.getHours();

        if (currentHour >= 4 && currentHour < 12) {
            setGreetingSentence(t('dashboard.user-menu.good-morning'));
        } else if (currentHour >= 12 && currentHour < 20) {
            setGreetingSentence(t('dashboard.user-menu.good-afternoon'));
        } else {
            setGreetingSentence(t('dashboard.user-menu.good-evening'));
        }
    });
    if (isError) {
        return <>Error</>;
    }
    if (isLoading) {
        return <>Lodaing</>;
    }
    let avatarSrc = data.avatar;
    if (avatarSrc == null) {
        avatarSrc = isDarkTheme ? dark_profile_avatar : profile_avatar;
    }
    // erros assing to constant
    // const avatarSrc = isDarkTheme ? dark_profile_avatar : profile_avatar;
    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={avatarSrc}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4" sx={{ paddingLeft: '1rem' }}>
                                                    {greetingSentence},
                                                </Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {isLoadingUserInfo
                                                        ? '...'
                                                        : userInfo.user_full_name ?? userInfo.user_email.split('@')[0]}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                        <Box sx={{ paddingRight: '2rem', paddingLeft: '2rem', paddingBottom: '2rem' }}>
                                            <Divider />
                                            <UpgradePlanCard />
                                            <Divider />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    onClick={() => {
                                                        {
                                                            dispatch({ type: CHANGE_THEME, isDark: isDarkTheme });
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <ThemeSection />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={<Typography variant="body2">{t('dashboard.user-menu.mode')}</Typography>}
                                                    />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={() => {
                                                        navigate('./settings');
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                {t('dashboard.user-menu.settings.menu-title')}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={() => {
                                                        navigate('/.');
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        {/* <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            style={{ width: '16px', fill: 'rgb(85, 85, 85)' }}
                                                        >
                                                            <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                                                        </svg> */}
                                                        <LaunchOutlinedIcon stroke={1.5} fontSize="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                {t('dashboard.user-menu.tiktag-website')}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={() => {
                                                        navigate('/logout');
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={<Typography variant="body2">{t('dashboard.user-menu.logout')}</Typography>}
                                                    />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
