import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useRadioGroup } from '@mui/material';

const client_id1 = 'hoYfc8lHgqQRIGORQV3z3SUZvbCi7NWmpTV55lH8';
const client_secret1 =
    'xVGOFR7yBwT4k3sRkg38PxVwJxPDTtN21gqC0K9GvwHbwqVMDyesXpnLjXVlavCSGXzCifBvgX95mYtPaBbIHH4FkLfKRhG1FIdu2hAeNToaaSQbalGznfgIbnmyWm23';

const client_id2 = 'oUf6zDTmTGauP7j838IroGkUThqm3AHgOVRNlj8P';
const client_secret2 =
    'BMYjyy1pK6pL747rXf6TVElMkccUIfFLG1wpWBu211LvjqGShzZNkdcKZtXfdFRp0iwUqVNaLisicfVD0fHsNpT93lCKxfzMtRfRGo2Sqnw5b7tYANpJESGsXSKrBd2W';
// client_id: 'hoYfc8lHgqQRIGORQV3z3SUZvbCi7NWmpTV55lH8',
// client_secret:
//     'xVGOFR7yBwT4k3sRkg38PxVwJxPDTtN21gqC0K9GvwHbwqVMDyesXpnLjXVlavCSGXzCifBvgX95mYtPaBbIHH4FkLfKRhG1FIdu2hAeNToaaSQbalGznfgIbnmyWm23'

// client_id: 'oUf6zDTmTGauP7j838IroGkUThqm3AHgOVRNlj8P',
// client_secret:
//     'BMYjyy1pK6pL747rXf6TVElMkccUIfFLG1wpWBu211LvjqGShzZNkdcKZtXfdFRp0iwUqVNaLisicfVD0fHsNpT93lCKxfzMtRfRGo2Sqnw5b7tYANpJESGsXSKrBd2W',
const AuthContext = React.createContext(null);

const AuthProvider = (props) => {
    const { children } = props;
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [accessToken, setAccessToken] = React.useState(null);

    const signin = async (username, password, callback) => {
        console.log('Login For ' + username + password);
        const user = {
            client_id: client_id2,
            client_secret: client_secret2,
            grant_type: 'password',
            username: username,
            password: password
        };
        axios
            .post('https://tiktag.co/api-auth/token/', user)
            .then((response) => {
                setIsLoggedIn(true);
                console.log('this is the response after login: ', response);
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);
                setAccessToken(response.data.access_token);
                callback(response.data, null);
                // handle successful login
            })
            .catch((error) => {
                console.log(error);
                console.log(error?.response?.data);
                if (error?.response?.data != null) {
                    callback(null, error?.response?.data);
                } else {
                    callback(null, { detail: 'Un expected error happen' });
                }
            });
    };

    const myGoogleLogin = async (accessToken, callback) => {
        console.log('My Google login fired');
        axios
            .post(`https://tiktag.co/api-auth/convert-token`, {
                token: accessToken,
                backend: 'google-oauth2',
                grant_type: 'convert_token',
                client_id: client_id2,
                client_secret: client_secret2
            })
            .then((res) => {
                setIsLoggedIn(true);
                // Save somewhere these access and refresh tokens
                console.log('yes this is the response data after convert token');
                console.log(res.data);
                // Initialize the access & refresh token in localstorage.
                // localStorage.clear();
                localStorage.setItem('access_token', res.data.access_token);
                localStorage.setItem('refresh_token', res.data.refresh_token);
                setAccessToken(res.data.access_token);
                callback(res.data, null);
                // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data['access_token']}`;
            })
            .catch((error) => {
                console.log(error);
                console.log(error?.res?.data);
                if (error?.res?.data != null) {
                    callback(null, error?.res?.data);
                } else {
                    callback(null, { detail: 'Un expected error happen' });
                }
            });
    };

    const myFacebookLogin = async (accessToken, callback) => {
        console.log('My Facebook login fired');
        axios
            .post(`https://tiktag.co/api-auth/convert-token`, {
                token: accessToken,
                backend: 'facebook',
                grant_type: 'convert_token',
                client_id: client_id2,
                client_secret: client_secret2
            })
            .then((res) => {
                // Save somewhere these access and refresh tokens
                console.log('yes this is the response data after convert token');
                console.log(res.data);
                // Initialize the access & refresh token in localstorage.
                localStorage.clear();
                localStorage.setItem('access_token', res.data.access_token);
                localStorage.setItem('refresh_token', res.data.refresh_token);
                setAccessToken(res.data.access_token);
                callback(res.data, null);
                // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data['access_token']}`;
            })
            .catch((error) => {
                console.log(error);
                console.log(error?.res?.data);
                if (error?.res?.data != null) {
                    callback(null, error?.res?.data);
                } else {
                    callback(null, { detail: 'Un expected error happen' });
                }
            });
    };

    const myAppleLogin = async (accessToken, callback) => {
        console.log('My Apple login fired');
        axios
            .post(`https://tiktag.co/api-auth/convert-token`, {
                token: accessToken,
                backend: 'apple-id',
                grant_type: 'convert_token',
                client_id: 'FPFz9YeXxw9LbHY3XJRe5Rp3T6J8BdERNppDRzkc',
                client_secret:
                    'XtlOp7DNc1JdLPEQnMOtkhYaD1CpmRKJ99rMD9u61zfkgMuQ8Tal5XdUDaeX92jQnBXnzb4NzUYqrK7o8sa3wRspv38CEJQHGwHGvTel1NHiQWfWUYgygAonrSI5C9du'
            })
            .then((res) => {
                setIsLoggedIn(true);
                // Save somewhere these access and refresh tokens
                console.log('yes this is the response data after convert token');
                console.log(res.data);
                // Initialize the access & refresh token in localstorage.
                // localStorage.clear();
                localStorage.setItem('access_token', res.data.access_token);
                localStorage.setItem('refresh_token', res.data.refresh_token);
                setAccessToken(res.data.access_token);
                callback(res.data, null);
                // axios.defaults.headers.common['Authorization'] = `Bearer ${res.data['access_token']}`;
            })
            .catch((error) => {
                console.log(error);
                console.log(error?.res?.data);
                if (error?.res?.data != null) {
                    callback(null, error?.res?.data);
                } else {
                    callback(null, { detail: 'Un expected error happen' });
                }
            });
    };

    const refreshTokens = async (callback) => {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken == null) {
            callback(null, { detail: 'Un expected error happen' });
            return;
        }
        const refresh = {
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
            client_id: client_id2,
            client_secret: client_secret2
        };
        axios
            .post('https://tiktag.co/api-auth/token/', refresh)
            .then((response) => {
                setIsLoggedIn(true);
                console.log('refreshing done : ' + JSON.stringify(response.data));
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);
                setAccessToken(response.data.access_token);
                callback(response.data, null);
            })
            .catch((error) => {
                console.log(error);
                console.log(error?.response?.data);
                if (error?.response?.data != null) {
                    callback(null, error?.response?.data);
                } else {
                    setIsLoggedIn(false);
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('access_token');
                    axios.interceptors.request.clear();
                    axios.interceptors.response.clear();
                    callback(null, { detail: 'Un expected error happen' });
                }
            });
    };

    const signup = async (username, fullname, password, callback) => {
        console.log('Signup For ' + username + password);
        const user = {
            email: username,
            full_name: fullname,
            password: password
        };
        axios
            .post('https://tiktag.co/api/register/', user)
            .then((response) => {
                setIsLoggedIn(true);
                console.log('this is the response after signup: ', JSON.stringify(response));
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);
                setAccessToken(response.data.access_token);
                callback(response.data, null);
                // handle successful login
            })
            .catch((error) => {
                console.log(error);
                console.log(error?.response?.data);
                if (error?.response?.data != null) {
                    callback(null, error?.response?.data);
                } else {
                    callback(null, { detail: 'Un expected error happen' });
                }
            });
    };

    const signout = async (callback) => {
        // TODO : Add Signout logic
        setIsLoggedIn(false);
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        axios.interceptors.request.clear();
        axios.interceptors.response.clear();
        //window.location.href = '/login';
        callback();
    };

    const value = { isLoggedIn, accessToken, signin, signout, refreshTokens, myGoogleLogin, signup, myFacebookLogin, myAppleLogin };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.elementType
};

export { AuthProvider, AuthContext };
