import { useContext } from 'react';
import { AuthContext } from 'providers/AuthProvider';

// ==============================|| USE AUTH HOOK   ||============================== //
/**
 * used yo manage Authentication (signing, signout, refreshTokens, isLoggedIn)
 * **Functions Signitures :**
 *
 * ```signin(username, password, callback(data ,error))```
 *
 * ```signout(callback())```
 *
 * ```refreshToken(callbak(data ,error))```
 *
 * **Props :**
 *
 * ```isLoggedIn : boolean```
 * @returns
 */
const useAuth = () => {
    return useContext(AuthContext);
};

export default useAuth;
