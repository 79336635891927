import { useQuery } from 'react-query';
import axios from 'axios';

const fetchUserDiskSpace = async () => {
    return (await axios.get('https://tiktag.co/api/user-disk-space/')).data;
};

/**
 * used to get current user info
 * @returns
 */
const useUserDiskSpace = () => {
    const { data, isLoading, isError } = useQuery('user-disk-space', fetchUserDiskSpace, { keepPreviousData: true, staleTime: Infinity });
    return { data, isLoading, isError };
};

export default useUserDiskSpace;
