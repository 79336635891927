import { combineReducers } from 'redux';
import cartReducer from './cartReducer';

// reducer import
import customizationReducer from './customizationReducer';
import themeReducer from './themeReducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    cart: cartReducer,
    theme: themeReducer
});

export default reducer;
