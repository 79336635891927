import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { CHANGE_THEME } from 'store/actions';
const ThemeSection = () => {
    const dispatch = useDispatch();
    const isDarkTheme = useSelector((state) => state.theme).isDark;
    return (
        // <Tooltip title={`Switch To ${isDarkTheme ? 'Light' : 'Dark'} Theme`} arrow>
        //     <IconButton
        //         aria-label="delete"
        //         size="large"
        //         onClick={() => {
        //             {
        //                 dispatch({ type: CHANGE_THEME, isDark: isDarkTheme });
        //             }
        //         }}
        //     >
        //         {isDarkTheme ? <LightModeIcon sx={{ color: 'white' }} fontSize="inherit" /> : <DarkModeIcon fontSize="inherit" />}
        //     </IconButton>
        // </Tooltip>
        <IconButton
            aria-label="delete"
            stroke={1.5}
            size="1.3rem"
            onClick={() => {
                {
                    dispatch({ type: CHANGE_THEME, isDark: isDarkTheme });
                }
            }}
            style={{ padding: '0px' }}
        >
            {isDarkTheme ? <LightModeIcon sx={{ color: 'white', padding: '0px' }} /> : <DarkModeIcon style={{ padding: '0px' }} />}
        </IconButton>
    );
};

export default ThemeSection;
