import { lazy } from 'react';
// util.js
import React from 'react';
// require auth component
import RequireAuth from 'ui-component/require-auth/RequireAuth';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// tiktag pages
const QrPage = Loadable(lazy(() => import('views/my-cards/QrPage')));
const ContentPage = Loadable(lazy(() => import('views/my-cards/Contents')));
const ConnectionsPage = Loadable(lazy(() => import('views/connections/Connections')));

const CardTabs = Loadable(lazy(() => import('views/my-cards/CardTabs')));
const Cards = Loadable(lazy(() => import('views/my-cards/Cards')));
const InsightPage = Loadable(lazy(() => import('views/insight/Insight')));
const ActiveDevices = Loadable(lazy(() => import('views/active-devices/ActiveDevices')));
const HomePage = Loadable(lazy(() => import('views/home/HomePage')));
const Groups = Loadable(lazy(() => import('views/groups/Groups')));
const Settings = Loadable(lazy(() => import('views/settings/Settings')));
// ==============================|| MAIN ROUTING ||============================== //

const ProtectedRoutes = {
    path: '/',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        /*{
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },*/
        {
            path: 'dashboard',
            element: <InsightPage />
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'cards/:cardId',
            element: <CardTabs />
        },
        {
            path: 'cards',
            element: <Cards />
        },
        {
            path: 'connections',
            element: <ConnectionsPage />
        },
        {
            path: 'insight',
            element: <InsightPage />
        },
        {
            path: 'active-devices',
            element: <ActiveDevices />
        },
        {
            path: 'groups',
            element: <Groups />
        },
        {
            path: 'settings',
            element: <Settings />
        }
    ]
};

export default ProtectedRoutes;
