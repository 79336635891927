import React from 'react';

function OtherComponent({ handleToggleTheme }) {
    const handleClick = () => {
        console.log('hi there');
        console.log(handleToggleTheme());
    };
    return (
        <div>
            <button onClick={handleClick}>HI MODE</button>
        </div>
    );
}

export default OtherComponent;
