import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// routing
import Routes from 'routes';
import { BrowserRouter } from 'react-router-dom';

// defaultTheme
import themes, { darkTheme } from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import ProtectedRoutes from 'routes/ProtectedRoutes';
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    //const [darkMode, setDarkMode] = useState(true);
    const darkMode = useSelector((state) => state.theme).isDark;
    // const theme1 = themes(customization);
    const theme = darkMode ? darkTheme(customization) : themes(customization);
    AOS.init();
    return (
        <BrowserRouter>
            <PayPalScriptProvider
                options={{
                    'client-id': 'AR94SrNCnS97E6RBmephPiAJ9SgOKcxp033dihx50kvssZOBTR1h1IqX6ZzyzYCSNdnUy0XEfNKMclER'
                }}
            >
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                        </NavigationScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            </PayPalScriptProvider>
        </BrowserRouter>
    );
};

export default App;
