import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    linearProgressClasses
} from '@mui/material';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

import { useTranslation } from 'react-i18next';

// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#fff',
        border: '1px solid rgb(15, 15, 15)'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        border: '1px solid rgb(15, 15, 15)',
        backgroundColor: theme.palette.primary.progress
    }
}));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.primary.light,
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '157px',
        height: '157px',
        background: theme.palette.primary[200],
        borderRadius: '50%',
        top: '-105px',
        right: '-96px',
        display: 'none'
    }
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    return (
        <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6" sx={{ color: theme.palette.primary[800] }}>
                            {t('dashboard.sidebar-menu.used')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color="inherit">{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

// ==============================|| SIDEBAR MENU Card ||============================== //
import useUserDiskSpace from 'hooks/useUserDiskSpace';
const MenuCard = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { data: userDiskSpace, isLoading: isLoadingUserDiskSpace, isError: isErrorUserDiskSpace } = useUserDiskSpace();
    console.log('this is the data that fetched from user disk space', userDiskSpace);

    return (
        <CardStyle>
            <CardContent sx={{ p: 2 }}>
                <List sx={{ p: 0, m: 0 }}>
                    <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                        <ListItemAvatar sx={{ mt: 0 }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    color: theme.palette.primary.main,
                                    border: 'none',
                                    borderColor: theme.palette.primary.main,
                                    background: '#fff',
                                    marginRight: '0px'
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    style={{ width: '18px', fill: 'rgb(85, 85, 85)' }}
                                >
                                    <path d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z" />
                                </svg>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{ mt: 0 }}
                            primary={
                                <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800], fontSize: '0.805rem' }}>
                                    {t('dashboard.sidebar-menu.your-space')}
                                </Typography>
                            }
                            secondary={
                                <Typography variant="caption">
                                    {' '}
                                    {isLoadingUserDiskSpace
                                        ? 'used / total'
                                        : userDiskSpace.total_used_disk_space +
                                          ' ' +
                                          '/' +
                                          ' ' +
                                          userDiskSpace.total_allowed_disk_space}{' '}
                                    MB
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
                <LinearProgressWithLabel
                    value={
                        isLoadingUserDiskSpace ? 0 : (userDiskSpace.total_used_disk_space / userDiskSpace.total_allowed_disk_space) * 100
                    }
                />
            </CardContent>
        </CardStyle>
    );
};

export default MenuCard;
