// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const ADD_TO_CART = '@cart/ADD_TO_CART';
export const REMOVE_FROM_CART = '@cart/REMOVE_FROM_CART';
export const DELETE_ITEM_FROM_CART = '@cart/DELETE_ITEM_FROM_CART';
export const CLEAR_CART = '@cart/CLEAR_CART';
export const CHANGE_THEME = '@theme/CHANGE_THEME';
